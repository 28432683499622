<template>
  <v-container class="mt-5 main-content " >
    <div class="Title" >
      <h1 class="text-h5">  QRコード 
        </h1>
    </div>

    <div style="width:100%;" class="text-center">
      <div style="max-width:500px; " class="mx-auto">
        <DialogQRCodeLogin
         
        />
      </div>
    </div>
    


  </v-container>
</template>

<script>
  
const DialogQRCodeLogin = () => import('@/components/DialogQRCodeLogin.vue');

  export default {
    name: 'QR',
    components: {
      DialogQRCodeLogin
    },
    data: () => ({
      slide:false,
    }),
  }
</script>


<style scoped>

  .Title > h1 {
    color: gray;
    font-weight: 400;
    border-bottom: 1px solid gray;
  }

  
.v-application{
  font-family: 'Noto Sans JP', sans-serif;
}

.application {
  font-family: 'Noto Sans JP', sans-serif;
}

</style>
